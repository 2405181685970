@media only screen and (min-width: 1200px) {
  .ra-input.level{
    width: 15%;
    padding-right: 10%;
    float: left;
  }
  .ra-input.tablet_count{
    width: 15%;
    padding-left: 10%;
    padding-right: 10%;
    float: left;
  }
  .ra-input.additional{
    width: 15%;
    padding-left: 10%;
    float: left;
  }
  .ra-input.ra-input-clear{
    clear:both;
  }
}
