[class*='MuiCard-root'] {
  overflow: visible !important;
}

.tooltip-content li {
  white-space: nowrap;
  list-style-type: none;
  margin-left: -40px;
}

.tooltip-content {
  background: white;
  position: relative;
  border: 1px solid #ddd;
  color: black;
  padding: 10px;
  z-index: 100;
  transform: translateX(-100%) translateY(15px);
  animation-name: example;
  animation-duration: 0.5s;
}

@keyframes example {
  0% {
    transform: translateX(-100%) translateY(15px);
    opacity:0;
  }
  99.9% {
    transform: translateX(-100%) translateY(15px);
    opacity:1;
  }
  100% {
    transform: translateX(-100%) translateY(15px);
  }
}

circle.frame-hover {
  stroke: "black";
  r: 6;
}
