.ra-input.substance{
  width: 35%;
  padding-right: 10px;
  float: left;
}
.ra-input.quantity{
  width: 30%;
  padding-left: 30px;
  float: left;
}
.ra-input.preview-button{
  margin-top: 1.5%;
  float: left;
}
.ra-input.ra-input-clear{
  clear:both;
}
